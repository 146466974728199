import { BillDeskImage, InfoIconYellow } from "images/icons"
import React from "react"
import { CustomButtonTw } from "./styles"
const FormStep2 = ({ accountStatus }: any) => {
    const disabledCondition = (!accountStatus?.toLowerCase()?.includes('collab access shared') && !accountStatus?.toLowerCase()?.includes('collab permissions missing'))
    return <>
        {accountStatus !== 'collab access shared' && <p className="flex items-center gap-1 bg-notifyYellow text-xs py-1 px-4 w-fit"><InfoIconYellow /><p className="font-bold">Note:</p>Complete the step 1 in order to proceed with the current step</p>}
        <div className="font-semibold text-lg mt-3">Payment gateway</div>
        <p className="text-pointsColor text-md mt-2">Payment gateways we offer: Select your preferred gateway to provide <br /> secure and flexible payment options for your customers.</p>

        <div className="flex flex-wrap gap-2 grid-cols-3 mt-4">
            <CustomButtonTw disabled={disabledCondition}>Billdesk</CustomButtonTw>
            <CustomButtonTw disabled={disabledCondition}>Setu</CustomButtonTw>
            <CustomButtonTw disabled={disabledCondition}>Decentro</CustomButtonTw>
            <CustomButtonTw disabled={disabledCondition}>Razorpay</CustomButtonTw>
            <CustomButtonTw disabled={disabledCondition}>Cashfree</CustomButtonTw>
            <CustomButtonTw disabled={disabledCondition}>Cred</CustomButtonTw>
        </div>
        <div className="flex gap-3 items-center justify-between pr-20">
            <p className="font-semibold text-lg mt-3">Cash on Delivery</p>

            <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" />
                <div className={`relative w-11 h-6 bg-greytext peer-focus:outline-none rounded-full peer dark:bg-greytext peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-greytext after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-greytext ${!disabledCondition && 'peer-checked:bg-paidGreen'} disabled:bg-greytext`}
                ></div>
            </label>
        </div>
        <p className="w-9/12 text-pointsColor">Offer your users Cash on Delivery (COD) with a simple toggle switch</p>
    </>
}
export default FormStep2