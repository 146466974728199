import axios from "axios"
import {GetAuthToken} from 'globalQuries'

const createNewOnBoarding = () => {
    try {
        localStorage.setItem('isOnboarding', 'true')
        localStorage.setItem('onboardingType', 'NEW')
        //localStorage.setItem("srAccountList", JSON.stringify(response?.data?.data?.account_info_list))
        localStorage.setItem('panel', `"[settings/general-settings, user-management, orders, finance, dashboard, abandoned-cart]"`)
        localStorage.removeItem('loggedInFromAdmin')
        window.location.href = '/home'
    }
    catch(e){}
}

export const fetchRatesDetail = async({queryKey}:any) => {
    const [unusedArgument, {domainName}] = queryKey;
    const auth = GetAuthToken();
    if(domainName){
    const response = await axios.get(`${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/aggregated-rates?status=not-approved&domain_name=${domainName}`,
    {
        headers : {
            "X-AUTH": auth,
        }
    })


    return response?.data?.result
}
}
export const fetchSrAccounts = async ({queryKey}:any) => {
    const[unusedArgument,{token}]=queryKey
    try {
        if(token){
        const response = await axios.get(
            `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/fetch-sr-checkout-accounts`,
            {
                headers: {
                    token: token
                },
            }
        );

        if (response?.data?.data?.account_info_list?.length === 0){
            createNewOnBoarding()
        } else {
            localStorage.removeItem('isOnboarding')
            localStorage.removeItem('onboardingType')
        }
        return response?.data?.data
    }
    } catch (error) {
        return {error:'something went wrong'}
        // setSrLoginError('something went wrong')
    }
};