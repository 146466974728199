import axios from 'axios';
import { GetAuthToken } from 'globalQuries';
import { notification } from 'antd';

export const createNewRule = async (data) => {
  const auth = GetAuthToken();
  const response = await axios.post(
    `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/shopify-function-rules/create-or-update`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};

export const updateRule = async (data) => {
  const auth = GetAuthToken();
  const response = await axios.put(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/update/`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};

export const fetchCitiesList = async () => {
  const response = await axios.get(
    `https://pickrr.com/location/get-city-list/`
  );
  return response;
};


export const fetchSellerCheckoutConfig = async () => {
  const auth = GetAuthToken();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    return response.data;
  } catch (error) {
    notification.error({
      message: error.message,
    });
  }
};
