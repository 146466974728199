import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  ExclamationOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  CustomModal,
  Container,
  IconContainer,
  PrimaryText,
  NormalText,
  CustomPickrrButton,
  Price,
} from "./styled";
import { FlexContainer, FlexEndContainer } from "components/UIElements";
import { Input, RadioChangeEvent } from 'antd';
import {Radio, InputNumber, Space, message, Spin} from 'antd';
import {IRefundDetails} from '../data';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {refundInitiate} from './script';
import {getOrderDetails} from '../../../EditOrder/script';


// mapping icons according to status
const icons = {
  /* @ts-ignore */
  success: <CheckOutlined />,
  /* @ts-ignore */
  failed: <CloseOutlined />,
  /* @ts-ignore */
  warning: <ExclamationOutlined />,
};


interface IProps{
    isRefundStatusModalOpen: boolean,
    setIsRefundStatusModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
    activeKey:number,
    refundDetails?: IRefundDetails,
    setRefundDetails?: React.Dispatch<React.SetStateAction<IRefundDetails>>,
    editOrderRefundAmount?: string | number
    editOrderId?:  string | number,
    callBack?: any 
    setIsEdit?: any
}

const RefundModal = ({isRefundStatusModalOpen,setIsRefundStatusModalOpen, activeKey, refundDetails, setRefundDetails, editOrderRefundAmount, editOrderId, callBack, setIsEdit}:IProps) => {
  const [partialRefundAmount, setPartialRefundAmount] = useState<number>();
  const [radioValue, setRadioValue] = useState<number | string>(1);
  const [loader, setLoader] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [responseStatus, setResponseStatus] = useState<string>();
  const [disabledRadio, setDisabledRadio] = useState<boolean>(false);
  const [disableRefundButton, setDisableRefundButton ] = useState(false);
  const [refId, setRefId] = useState<string | number>()
  const [orderId, setOrderId] = useState<string | number>()
  const queryClient = useQueryClient();
  const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin")

  function modalCloseHandler(){
    setIsConfirmModalOpen(false);
    setIsRefundStatusModalOpen(false);
    setResponseStatus('');
    setLoader(false);
    setRadioValue(1)
    if(!editOrderId){
    setRefundDetails({
      ...refundDetails,
      amount: null, 
      fastrrOrderId: null,
      refundAmt: null,
      cartId: null,
      orderId: null,
      orderFulfillmentStatus: null
    })
  }
  }

  function refundTypeChangeHandler(e: RadioChangeEvent){
    setRadioValue(e.target.value);
    setPartialRefundAmount(0)
    if(e.target.value === 2){
      setDisableRefundButton(true) 
    }else {
      setDisableRefundButton(false) 
    }

  };

  function partialAmountHandler(value:number){
    setPartialRefundAmount(value)
    if(value >= 1){
      setDisableRefundButton(false);
    }else {
      setDisableRefundButton(true);
    }
  }

  const {data: orderDetails, isLoading , isError: fetchingOrderDetailError, refetch} = useQuery(
    ['orderData', {orderId,loggedInFromAdmin}],
    getOrderDetails,
    {enabled: false}
  );


  useEffect(() => {
    setOrderId('');
    if((refundDetails?.orderId || editOrderId) && activeKey){
      const orderId = refundDetails?.orderId ? refundDetails?.orderId : editOrderId;
      setOrderId(orderId);
      refetch() 
    }
  }, [refundDetails, activeKey, orderId, editOrderId])

  useEffect(() => {
    const orderData:any = orderDetails?.data?.transactionsDetails;
    if(orderData?.total_paid_amount <= orderData?.total_refunded_amount &&
       orderData?.total_paid_amount - orderData?.total_refunded_amount >= 1){
      setDisableRefundButton(true) 
    }
  }, [orderDetails])
  
  const mutation = useMutation(refundInitiate, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data) => {
      setResponseStatus(data?.success)
      setIsConfirmModalOpen(true)
      setRefId(data?.data?.id);
      queryClient.invalidateQueries('ordersData');
      queryClient.invalidateQueries('orderData');
      if(editOrderId){
        setIsEdit(false)
        callBack()
      }
    },
  });

  function refundInitiateHandler(){
    const totalAmount = orderDetails?.data?.transactionsDetails?.total_paid_amount;
    const refundAmount = orderDetails?.data?.transactionsDetails?.total_refunded_amount;

    if(totalAmount - refundAmount >= 1){
      mutation.mutate(
        {
          amount: radioValue === 1 
            ? (totalAmount - refundAmount)
            : partialRefundAmount,
          order_id:orderDetails?.data?.orderDetails?.id,
          cart_id: orderDetails?.data?.orderDetails?.cartId
        }
      )
    }else {
      message.info('minimum amount should be 1Rs')
    }
  }

    const ResponseSuccess = (
        <>
          <IconContainer color="#19870B">{icons["success"]}</IconContainer>
          <PrimaryText>Refund is successfully processed</PrimaryText>
          <NormalText>
            <span style={{ color: "#F0543C" }}>Note</span> : If refund is not
            reflected in the customer’s account after the date mentioned, the customer
            should reach out to their bank with the Refund
          </NormalText>
          <NormalText> Reference ID: {refId}</NormalText>
          <CustomPickrrButton
            color="transparent"
            background-color="transparent"
            onClick={modalCloseHandler}
          >
            Done
          </CustomPickrrButton>
        </>
      );
      
      const ResponseError = (
        <>
          <IconContainer color="#E22E2E">{icons["failed"]}</IconContainer>
          <PrimaryText>Refund Request Failed</PrimaryText>
          <NormalText>
            Your refund request for order ID 
            #{refundDetails?.orderId ? refundDetails?.orderId : editOrderId} is 
            <span style={{ color: "#F0543C" }}>failed</span>
          </NormalText>
          <NormalText>
            Please Contact at <a href="#"><u>fastrr@support.com</u></a> for help
          </NormalText>
          <CustomPickrrButton
            color="transparent"
            background-color="transparent"
            onClick={modalCloseHandler}
          >
            Try Again
          </CustomPickrrButton>
        </>
      );
      
      const Confirm = (
        <>
          <IconContainer color="#FC6A00">{icons["warning"]}</IconContainer>
          <PrimaryText>Please Confirm to Refund the amount</PrimaryText>
          <div style={{
              background: 'rgba(240, 246, 253, 0.6)',
              borderRadius: '8px',
              width: '328px',
              height:'115px',
              display:'flex',
              justifyContent: 'center',
              alignItems:'center',
              flexDirection: 'column'
            }}>
              <Radio.Group onChange={refundTypeChangeHandler} value={radioValue}>
                <Space direction="vertical">
                    <Radio value={1} 
                      // disabled={disabledRadio}
                    >
                  Full Refund of 
                      &#8377;{
                        Number(orderDetails?.data?.transactionsDetails?.total_paid_amount
                        - orderDetails?.data?.transactionsDetails?.total_refunded_amount)?.toFixed(2)}
                    </Radio>
                    {
                      orderDetails?.data?.transactionsDetails?.all_transactions?.[0]?.gateway?.toUpperCase() !== "SNAPMINT" && 
                      <Radio value={2}>Partial Refund</Radio>
                    }
                </Space>
              </Radio.Group>
              {radioValue === 2 &&
                <div>
                  <InputNumber 
                    // min={1} 
                    max={orderDetails?.data?.transactionsDetails?.total_refunded_amount 
                      ? (orderDetails?.data?.transactionsDetails?.total_paid_amount - orderDetails?.data?.transactionsDetails?.total_refunded_amount)
                      : orderDetails?.data?.transactionsDetails?.total_paid_amount} 
                    onChange={partialAmountHandler} 
                    style={{marginLeft:10}}
                    // onInput ={isNumber}
                  />
                </div>
              }
          </div>
          <NormalText size = {14} style={{ marginTop: 8, color: "#FF5B51" }}>
            Once refund is processed, it cannot be cancelled
          </NormalText>
          <NormalText size = {12} style={{ marginTop: 0, color: "#0C3784" }}>
            Note: The refund amount must be greater than or equal &#8377;1 
          </NormalText>

          <div>
            <FlexContainer style={{ gap: 12, marginTop: 8 }}>
              <CustomPickrrButton
                onClick={modalCloseHandler}
                color="transparent"
                background-color="transparent"
              >
                Cancel
              </CustomPickrrButton>
              <CustomPickrrButton
                color="blue"
                background-color="blue"
                onClick = {refundInitiateHandler}
                loading = {mutation?.isLoading}
                disabled = {disableRefundButton}
                // PartialRefundAmount
              >
                Confirm
              </CustomPickrrButton>
            </FlexContainer>
          </div>
        </>
      );
      
  return (
    <CustomModal
      visible={isRefundStatusModalOpen}
      footer={null}
      onOk={modalCloseHandler}
      onCancel={modalCloseHandler}
      key = {activeKey}
      width={645}
    >
      {isLoading ? 
    
      <FlexContainer>
        <Spin /> 
      </FlexContainer>
      : 
      <Container>
        {
        !isConfirmModalOpen 
        ? Confirm 
        : responseStatus ? ResponseSuccess : ResponseError
      }
      </Container>
      }
    </CustomModal>
  );
};

export default RefundModal;