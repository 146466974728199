import React, { useEffect, useState, useRef } from "react";
import { Form } from "antd";
import { CustomInput, LabelText, RestButton } from "../styled";
import { SpaceBetweenContainer } from "components/UIElements";
import { SearchOutlined } from "@ant-design/icons";
import SearchResult from "./SearchResult";
import SearchedProductList from "./SearchedProductsList";
import { productSearch } from "./script";
import { getGeneralDetails } from "components/Settings/GeneralSettings/script";
import { useQuery } from "react-query";
import ProductSearchModal from "./ProductSearchModal";
import { HeadingText } from "../CriteriaTypes/styled";

interface ISellerDetails {
  sellerId: string;
  storeUrl: string;
  searchType: string;
  channel: string;
}

const ProductSearch = ({
  selectedItems,
  setSelectedItems,
  type,
  discountType,
  form,
  sellerConfigValues,
  bundleKey,
  showReset = false,
}: any) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [inputValue, setInputValue] = useState<string | null>();
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [editItemId, setEditItemId] = useState<number | string>();

  const sellerDetails: any = {
    sellerId: sellerConfigValues?.seller_id,
    storeUrl: sellerConfigValues?.domain_name,
    searchType: type,
    channel: sellerConfigValues?.channel,
  };

  // const [sellerDetails, setSellerDetails] = useState<ISellerDetails>({
  //     sellerId: "62175c53b2b9830831b586ec",
  //     storeUrl: "harish-30.myshopify.com",
  //     searchType: type,
  //     channel: "shopify",
  //   });

  const [selectProductId, setSelectProductId] = useState<number | string>();

  /*getting seller details*/
  // const { isLoading: sellerConfigLoading, data: configData } = useQuery(
  //     "generalSettings",
  //     getGeneralDetails
  // );

  // useEffect(() => {
  //   if(type){
  //     setSellerDetails({
  //         ...sellerDetails,
  //         sellerId: configData?._id,
  //         storeUrl: configData?.domain_name,
  //         searchType: type,
  //         channel: configData?.platform_details?.channel,
  //     });
  //   }
  // }, [sellerConfigLoading, configData, type]);

  const { data: searchedProduct, isLoading: sellerActiveLoading } = useQuery(
    ["productSearch", { searchTerm, ...sellerDetails }],
    productSearch
  );

  const onSearch = (value: string) => {
    if (value) setSearchTerm(value);
    setInputValue(null);
  };

  const productSearchModalProps: any = {
    isProductModalOpen,
    setIsProductModalOpen,
    selectProductId,
    sellerDetails,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    editItemId,
    setEditItemId,
    randomKey: Math.random() * 2002,
    discountType,
    form,
    setSelectProductId,
  };

  const inputRef = useRef();

  return (
    <div key={bundleKey}>
      <div>
        <LabelText>
          <SpaceBetweenContainer>
            {discountType==='freebie'?<div style={{marginLeft:'5px', marginBottom:'6px' ,color:"black"}}>Select Freebie Product</div>:`Search ${type}`}
            {(discountType === "bxgy" || showReset) && (
              <RestButton onClick={() => setSelectedItems([])}>
                Reset
              </RestButton>
            )}
          </SpaceBetweenContainer>
        </LabelText>
        <CustomInput
          placeholder={`Search or Select ${type}`}
          /* @ts-ignore */
          suffix={<SearchOutlined />}
          ref={inputRef}
          value={inputValue}
          onPressEnter={(e: any) => {
            if (e.target.value) {
              onSearch(e.target.value);
            }
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
            if (!e.target.value) {
              onSearch("");
              setSearchTerm("");
            }
          }}
          disabled={!type}
        />
        {(searchedProduct?.products?.length ||
          searchedProduct?.collections?.length) &&
          searchTerm?.length && (
            <SearchResult
              isLoading={sellerActiveLoading}
              data={searchedProduct.products || searchedProduct?.collections}
              setIsProductModalOpen={setIsProductModalOpen}
              setSelectProductId={setSelectProductId}
              setEditItemId={setEditItemId}
              type={type}
              setSearchTerm={setSearchTerm}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              discountType={discountType}
            />
          )}
        {sellerActiveLoading && (
          <div
            style={{
              border: "1px solid #e6e6e6",
              padding: "10px",
              borderTop: "none",
              maxHeight: "150px",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          >
            Loading...
          </div>
        )}
      </div>

      {selectedItems?.length > 0 && (
        <SearchedProductList
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setSelectProductId={setSelectProductId}
          setEditItemId={setEditItemId}
          setIsProductModalOpen={setIsProductModalOpen}
          type={type}
          discountType={discountType}
        />
      )}

      <ProductSearchModal {...productSearchModalProps} />
    </div>
  );
};

export default ProductSearch;
