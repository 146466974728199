import React, { useState, KeyboardEvent, useEffect, useContext } from "react";
import Stepper from "./Stepper";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import { DataContext } from "context/DataProvider";
const SRCForm = ({setShowLanding}:any) => {
    const [currentStep, setCurrentStep] = useState<number>(0)
    const {shopifyUrl,setShopifyUrl,approveRequest,setApproveRequest,accessCode,setAccessCode,shareRequest,setShareRequest}=useContext(DataContext)
    const {
        sellerConfig,
    } = useContext(DataContext);
    useEffect(()=>{
        if (sellerConfig?.domain_name){
            setShopifyUrl(sellerConfig?.domain_name)
        }
        if (sellerConfig?.collab_code){
            setAccessCode(sellerConfig?.collab_code)
        }
    },[])
    const handleNext = () => {
        if (currentStep<2){
        setCurrentStep(prev => prev + 1)
        }
    }
    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1)
        }
        else{
            setShowLanding(true)
        }
    }

    const steps = [{ label: 'Connect Your Store', sublabel: 'Seemlessly link your Shopify store' }, { label: 'Payment Setup', sublabel: 'Configure to start accepting secure payments ' }, { label: 'Review & Go Live', sublabel: 'Review and grow your sales with us' }]

    return <>
        <div 
        className="bg-white shadow-xl pl-12 py-8 pr-8 rounded-md h-1/3 w-2/5">
            <Stepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />

            {currentStep == 0 && <FormStep1 shopifyUrl={shopifyUrl} setShopifyUrl={setShopifyUrl} setAccessCode={setAccessCode} handleNext={handleNext} accessCode={accessCode} accountStatus={sellerConfig?.account_status?.toLowerCase()} approveRequest={approveRequest} setApproveRequest={setApproveRequest} setShowLanding={setShowLanding} shareRequest={shareRequest} setShareRequest={setShareRequest}/>}
            {currentStep == 1 && <FormStep2 accountStatus={sellerConfig?.account_status?.toLowerCase()} />}
            {currentStep==2 && <FormStep3 accountStatus={sellerConfig?.account_status?.toLowerCase()}/>}

            <div className="flex gap-2 mt-3">
                <button type="button" className="mt-3 flex gap-2 focus:outline-none border text-purpleText border-backPurple bg-backPurple focus:ring-0 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-backPurple dark:hover:bg-backPurple disabled:bg-greytext disabled:text-black" onClick={() => { handleBack() }}>Back</button>
                <button type="button" className="mt-3 flex gap-2 focus:outline-none border border-purpleText text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-purpleText dark:hover:bg-purpleText" onClick={() => {handleNext()}}>{currentStep!==2?'Next':'Submit'}</button>
            </div>
        </div>
    </>
}
export default SRCForm