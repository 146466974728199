import React from "react"
const VideoModal = ({ setViewVideoModal, videoID}: any) => {
    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => { setViewVideoModal(false) }} />
            <div className="flex flex-col justify-center items-center relative z-50 bg-refundWhiteBg rounded-lg h-1/2 w-1/2 ">
                <iframe
                    className="w-full h-full"
                    src={`https://www.youtube.com/embed/${videoID}`}
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

        </div>
    </>
}
export default VideoModal