import React, { useEffect, useState, useContext, ChangeEvent } from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import moment from 'moment'
import { fetchOrdersData, handleAllDownload, handleSingleDownload, handleMultipleDownload } from './script'
import { useQuery } from 'react-query'
import { ITableRowData, IRefundDetails } from './data'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DataContext } from 'context/DataProvider'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomReactTable from 'UiComponents/Table'
import { CustomButton, CustomDownloadButton, FlexRowTw, PaymentStatusTag, TableContainerTw, TagsStyle, TextStyle } from './styles'
import { AOVCard, AmountOfCard, SortByCard, TotalCard, WalletCard } from 'UiComponents/Cards'
import { CustomReactInput } from 'UiComponents/Select'
import { DownIconTable, ExportIcon, FilterIcon, RefundIcon, UpDownIcon } from 'images/icons'
import { Ndata } from 'UiComponents/SideBar/data'
import Filter from 'UiComponents/CustomFilter/Filter'
import { SendNotifications } from 'UiComponents/Notifications/TableNotification'
import FilterNew from 'pages/AbandonedCart/FilterNew'
import RefundModal from './Refund/RefundModal'
import { RefundButtonTw } from 'pages/EditOrderNew/styled'
import "../../../pages/Loader.css"

const OrdersTabNew = ({ fromDate, toDate, source, state }: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [inputValue, setInputValue] = useState<any>('')
    const [tableData, setTableData] = useState<ITableRowData[]>([])
    const [viewMoreState, setViewMoreState] = useState<any>()
    const [selectedRows, setSelectedRows] = useState<any>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [rtoFilter, setRTOFilter] = useState<string[]>([])
    const [deliveryFilter, setDeliveryFilter] = useState<string[]>([])
    const [paymentFilter, setPaymentFilter] = useState<string[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [searchKey, setSearchKey] = useState<string>('platform')
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0)
    const [displaySort, setDisplaySort] = useState<boolean>(false)
    const TagsValue = ['Order Id', 'Date', 'Items', "Payment Total", 'Payment Transaction Id']
    const [sortValue, setSortValue] = useState<any>("")
    const [filter, setFilter] = useState<any>([])
    const [displayFilter, setDisplayFilter] = useState<boolean>(false)
    const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
    //refund
    const { sellerId, sellerDomain, channel } = useContext(DataContext)
    const [refundDetails, setRefundDetails] = useState<IRefundDetails>()
    const [refundTooltipMessage, setRefundTooltipMessage] = useState<string>()
    const [refundModalActiveKey, setRefundModalActiveKey] = useState<any>()
    const [isRefundStatusModalOpen, setIsRefundStatusModalOpen] = useState<boolean>(false);

    const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);
    const [modeFilter, setModeFilter] = useState<boolean>(false)
    const [showDeliveryFilter, setShowDeliveryFilter] = useState<boolean>(false)
    const modeKeys = ['COD', 'Prepaid']
    const deliveryMethodKeys = ['Rush', 'Standard', 'Express']
    const [checkoutFilterMode, setCheckoutFilterMode] = useState<any[]>([])
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)

    const Paymentmapping: any = {
        'COD': 'cod',
        'Prepaid': 'prepaid',
    }
    const DeliveryMapping: any = {
        'Rush': 'Rush',
        'Standard': 'Standard',
        'Express': 'Express'
    }

    const addOnBefore = [
        { value: 'platform', label: 'Order ID' },
        { value: 'phone', label: 'Phone Number' },
        { value: 'fastrr', label: 'Fastrr Order ID' },
        { value: 'order', label: 'Platform ID' }
    ];

    const getModifiedAddOnBefore = (channel:any)=>{
        if(channel==='woocommerce'){
            return [
                { value: 'order', label: 'Platform ID' },
                { value: 'phone', label: 'Phone Number' },
                { value: 'fastrr', label: 'Fastrr Order ID' }
            ];
        }

        return addOnBefore;
    }

    const modifiedAddOnBefore = getModifiedAddOnBefore(channel);

    const refundHandler = (rowData: ITableRowData) => {
        const currentDate = moment(new Date()).format('DD-MM-YYYY');
        const orderDate = moment(rowData?.orderDetails?.orderDate).format('DD-MM-YYYY');
        const dateDiff = moment(currentDate, "DD-MM-YYYY").diff(moment(orderDate, "DD-MM-YYYY"), 'days')


        // (Math.round(rowData.orderDetails?.amount) !== Math.round(rowData.orderDetails?.refundAmt))
        if (
            ((rowData?.orderDetails?.paymentStatus?.toLocaleLowerCase() !== "refunded" &&
                rowData?.orderDetails?.paymentStatus?.toLocaleLowerCase() !== "refund_initiated") &&
                dateDiff <= 60
            )
        ) {
            setIsRefundStatusModalOpen(true);
            setRefundDetails({
                amount: rowData?.orderDetails?.amount,
                fastrrOrderId: rowData?.orderDetails?.fastrrOrderId,
                refundAmt: rowData?.orderDetails?.refundAmt,
                cartId: rowData?.orderDetails?.cartId,
                orderId: rowData?.orderDetails?.orderId,
                orderFulfillmentStatus: rowData?.orderDetails?.orderFulfillmentStatus
            })
            setRefundModalActiveKey(rowData?.orderDetails?.fastrrOrderId)
        }
    }

    useEffect(() => {

        if (state) {
            setDeliveryFilter(state?.deliveryFilter)
            setPaymentFilter(state?.paymentFilter)
            setRTOFilter(state?.rtoFilter)
            setSearchKey(state?.searchKey)
            setSearchTerm(state?.searchTerm)
            setInputValue(state?.searchTerm)
            setPrevPageNumber(1)
        }
    }, [state])

    const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin")

    const Filterdata: Ndata = {
        Source: [
            {
                key: 1,
                name: 'Fastrr',
                count: 20
            },
            {
                key: 2,
                name: 'Online - website',
                count: 1
            }
        ],
        "Order Status": [
            {
                key: 3,
                name: 'Open',
                count: 20
            },
            {
                key: 4,
                name: 'Archived',
                count: 20
            },
            {
                key: 5,
                name: 'Cancelled',
                count: 20
            }
        ],
        "Payment Gateway": [
            {
                key: 6,
                name: 'Setu',
                count: 20
            },
            {
                key: 7,
                name: 'Fastrr',
                count: 20
            },
            {
                key: 8,
                name: 'RazorPay',
                count: 20
            }
        ]

    }

    const {
        data: ordersData,
        isLoading,
    } = useQuery<any>(
        ['ordersData', { pageNumber, fromDate, toDate, searchTerm, ...(searchTerm && { searchKey }), rtoFilter, paymentFilter, deliveryFilter, source, loggedInFromAdmin }],
        fetchOrdersData,
    )

    useDeepCompareEffect(() => {
        setSelectedRowKeys([])
    }, [fromDate, toDate, searchTerm, searchKey, rtoFilter, paymentFilter, deliveryFilter, pageNumber])

    useEffect(() => {
        if (state) {
            setTimeout(() => {
                setPageNumber(state?.pageNumber)
                navigate(location.pathname, { replace: true })
            }, 100);
        } else {
            setPageNumber(1)
        }
    }, [fromDate, toDate])
    useEffect(() => {
        setTableData(ordersData?.orders)
    }, [ordersData])


    useEffect(() => {
        setViewMoreState({
            pageNumber,
            fromDate,
            toDate,
            searchTerm,
            searchKey,
            rtoFilter,
            paymentFilter,
            deliveryFilter,
            source
        })
    }, [pageNumber, fromDate, toDate, searchTerm, rtoFilter, paymentFilter, deliveryFilter, source])



    const PaymentModeText: any = {
        'PREPAID': '#0033E6',
        'PARTIAL_PAID': '#399F50',
        'CASH_ON_DELIVERY': '#E46A11'
    }

    const PaymentModeBg: any = {
        'PREPAID': '#EBEFFD',
        'PARTIAL_PAID': '#ecfaef',
        'CASH_ON_DELIVERY': '#FDF1E8'

    }
    const PaymentStatusColor: any = {
        'Success': '#1F806E',
        'Pending': '#B53600C9',
        'REFUNDED': '#09C4FF',
        'CAPTURED': '#3E75C8',
        'partial_paid': '#453593'
    }

    const isChecked = (key: any, row: any) => {
        return key === row?.orderDetails.orderId
    }
    const onSelectAll = (pageData: any) => {
        setSelectedRowKeys((keys) => keys.length === pageData?.length ? [] : [...pageData?.map((key: any) => {
            return key?.original?.orderDetails?.orderId
        })])
        setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
    }


    const onSearchKeyChange = (value: string) => {
        setSearchKey(value)
    }

    const onSearch = (value: string) => {
        setSearchTerm(value)
        setPageNumber(1)
    }

    const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setInputValue(event.target.value)
        if (!event.target.value) {
            onSearch('')
            setPageNumber(prevPageNumber)
        }
    }

    const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        if (!searchTerm) {
            setPrevPageNumber(pageNumber)
        }
        if (event.target.value) {
            onSearch(event.target.value)
        }
    }



    const sortPaymentTotal = (rowA: any, rowB: any) => {
        const paymentTotalA = rowA?.original?.orderDetails?.subTotal || 0;
        const paymentTotalB = rowB?.original?.orderDetails?.subTotal || 0;
        return paymentTotalA - paymentTotalB;
    };


    const handleRowSelect = (row: any) => {
        const rowIndex = selectedRows.findIndex((r: any) => {
            return r?.original?.orderDetails?.clientOrderId === row?.original?.orderDetails?.clientOrderId
        });
        if (rowIndex === -1) {
            setSelectedRows([...selectedRows, row]);
            setSelectedRowKeys([...selectedRowKeys, row?.original?.orderDetails?.orderId])
        }
        else {
            const updatedSelectedRows = [...selectedRows];
            updatedSelectedRows.splice(rowIndex, 1);
            setSelectedRows(updatedSelectedRows);
            setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != row?.original?.orderDetails?.orderId)]);
        }
    };

    const viewMoreHandler = (data: any) => {
        if (data?.orderDetails?.orderId) {
            navigate(`/orders/order-details/${data?.orderDetails?.orderId}`,
                {
                    state: {
                        orderState: data,
                        ...viewMoreState
                    }
                }
            )
        }
        return
    }


    const col: object[] = [
        {
            Header: 'Client Order ID',
            disableSortBy: false,
            tooltip: true,
            accessor: (item: any, index: number) => {
                const randomNumber = Math.random()
                const { orderDetails: { clientOrderId = '', fastrrOrderId = '', orderId = '' } = {} } = item ?? {};
                return (
                    <>
                        {/*// @ts-ignore */}
                        <TextStyle className='min-w-24 tracking-wide cursor-pointer' onClick={() => viewMoreHandler(item)}>
                            <div className="flex" data-tip data-tooltip-id={`${randomNumber}`} style={{ alignItems: 'center', height: '50px' }}>
                                <div>
                                    {(channel==='woocommerce'?orderId:clientOrderId) || <span className='ml-7'>-</span>}
                                </div>
                                <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
                                    <DownIconTable />
                                </div>
                            </div>
                            <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
                                <div className="max-w-32 h-20 text-clear pr-4" onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                    <div>Fastrr ID</div>
                                    <div className="font-normal text-searchColor">{fastrrOrderId || '-'}</div>
                                    <div>Platform ID</div>
                                    <div className="font-normal text-searchColor">{orderId || '-'}</div>
                                </div>
                            </ReactTooltip>
                        </TextStyle>
                    </>

                )

            }
        },
        {
            Header: 'Date & Time',
            disableSortBy: false,
            accessor: (item: any) => {
                const { orderDetails: { orderDate = '' } = {} } = item ?? {};
                return <>
                    {/*// @ts-ignore */}
                    <TextStyle>
                        <div className='w-max'>
                            {orderDate ? moment(orderDate).format('Do MMMM, h:mm a') : '-'}
                        </div>
                    </TextStyle>
                </>
            }
        },
        {
            Header: 'Customer',
            disableSortBy: false,
            tooltip: true,
            accessor: (item: any) => {
                const randomNumber = Math.random()
                const { orderDetails: { orderId = '' } = {}, customerDetails: { name = '', lastName = '', phone = '', email = '', shippingAddress = '' } = {} } = item ?? {};
                return <div className="flex justify-start items-center" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    {/*// @ts-ignore */}
                    <TextStyle>
                        <div className='block max-w-[180px] overflow-hidden text-ellipsis break-normal whitespace-nowrap' data-tip data-tooltip-id={`${randomNumber}`}>{
                            (name || lastName) ?
                                `${name ?? ''} ${lastName ?? ''}` :
                                '-'
                        }</div>

                        <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

                            <div className="w-fit px-2 text-clear" onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                {/*// @ts-ignore */}
                                <TextStyle className='font-bold max-w-60 text-wrap'>{name} {lastName}</TextStyle>
                                <div>{phone || '-'}</div>
                                <div>{email || '-'}</div>
                                <div className='max-w-60 text-wrap'><p>{shippingAddress || '-'}</p></div>
                            </div>
                        </ReactTooltip>
                    </TextStyle>
                </div>
            }
        },

        {
            Header: 'Payment Total',
            disableSortBy: false,
            customSort: sortPaymentTotal,
            accessor: (item: any) => {
                const { orderDetails: { amount = 0 } = {} } = item ?? {};
                return (
                    <TextStyle className='min-w-24'>
                        <div>{amount ? `₹ ${amount.toFixed(2)}` : '-'}</div>
                    </TextStyle>
                )

            }
        },
        {
            Header: 'Payment Status',
            disableSortBy: true,
            accessor: (item: any) => {

                {/*// @ts-ignore */ }
                const { orderDetails: { paymentStatus = '' } = {} } = item ?? {};
                const display = paymentStatus
                return (
                    <>
                        <span className="capitalize pl-4">
                            {display &&
                                <ul className='list-disc text-xl'>
                                    <li style={{ color: PaymentStatusColor[display] }}><PaymentStatusTag>
                                        <span>{display?.toLowerCase()}</span>
                                    </PaymentStatusTag>
                                    </li>
                                </ul>
                            }
                        </span>
                    </>)
            }
        },

        {
            Header: <span className='flex items-center relative'>
                Payment Mode <span onClick={() => { setModeFilter(!modeFilter) }} className='cursor-pointer'> <FilterIcon /></span>
                {modeFilter && <div className='absolute' style={{ top: "35px", left: '-10px' }}>
                    <FilterNew filters={modeKeys} filterKey={paymentFilter} setFilterKey={setPaymentFilter} multipleSelect={false} setIsOpen={setModeFilter} setPageNumber={setPageNumber} mapping={Paymentmapping} /></div>}

            </span>,
            accessor: 'payment-mode-amount',
            disableSortBy: true,
            Cell: ({ cell: { value }, row }: any) => {
                let paymentMethod = row?.original?.orderDetails?.paymentMethod?.toUpperCase()
                return (
                    <TextStyle className='min-w-28'>
                        <div style={{ color: `${PaymentModeText[paymentMethod] || PaymentModeText['CASH_ON_DELIVERY']}`, background: `${PaymentModeBg[paymentMethod] || PaymentModeBg['CASH_ON_DELIVERY']}` }} className={`flex justify-center items-center py-0.5 rounded-2xl w-fit px-4`}>
                            {paymentMethod === 'PREPAID'
                                ? 'Prepaid'
                                : row?.original?.orderDetails?.paymentMethod === "partial_paid"
                                    ? "Partial paid"
                                    : 'COD'
                            }
                        </div>
                    </TextStyle>
                )
            }
        },
        {
            Header: <span className='flex items-center relative'>
                Delivery Method <span onClick={() => { setShowDeliveryFilter(!showDeliveryFilter) }} className='cursor-pointer'> <FilterIcon /></span>
                {showDeliveryFilter && <div className='absolute' style={{ top: "35px", left: '-10px' }}><FilterNew filters={deliveryMethodKeys} filterKey={deliveryFilter} setFilterKey={setDeliveryFilter} multipleSelect={true} setIsOpen={setShowDeliveryFilter} setPageNumber={setPageNumber} mapping={DeliveryMapping} /></div>}

            </span>,
            accessor: 'delivery-method',
            disableSortBy: true,
            Cell: ({ cell: { value }, row }: any) => {
                return <>
                    <TextStyle>
                        {row?.original?.orderDetails?.shippingMethod}
                    </TextStyle>
                </>
            }
        },


        {
            Header: 'Tags',
            disableSortBy: true,
            accessor: (item: any) => {
                const { orderDetails: { tags = "" } = {} } = item ?? {};
                return (
                    <div className='flex flex-wrap grid-cols-3 gap-2 w-max'>
                        {tags && (
                            tags?.split(',').map((element: any) => {
                                return (
                                    <TagsStyle>
                                        {element}
                                    </TagsStyle>
                                )
                            }
                            ))}
                    </div>
                )

            }
        },
        {
            Header: 'Payment Gateway',
            disableSortBy: true,
            accessor: (item: any) => {
                const { orderDetails: { gateway = '' } = {} } = item ?? {};
                return (
                    <>
                        {/*// @ts-ignore */}
                        <TextStyle className='min-w-28'>
                            {gateway?.toUpperCase()==='POTLEE'?'SETTLE':gateway || '-'}
                        </TextStyle>
                    </>
                )
            }
        },
        {
            Header: 'PG Transaction ID',
            disableSortBy: false,
            accessor: (item: any) => {
                const { orderDetails: { transactionId = '',  paymentMethod = '' } = {} } = item ?? {};

                return (
                    <>
                        {/*// @ts-ignore */}
                        <TextStyle>
                            {transactionId && paymentMethod.toUpperCase() !== "CASH_ON_DELIVERY" ? transactionId : '-'}
                        </TextStyle>
                    </>
                )

            }
        },
        {
            Header: 'Source',
            disableSortBy: true,
            accessor: (item: any) => {
                const { orderDetails: { source = '' } = {} } = item ?? {};
                return (
                    <TextStyle>
                        {source || "N/A"}
                    </TextStyle>

                )

            }
        },
        {
            Header: 'Items',
            disableSortBy: false,
            tooltip: 'true',
            accessor: (itemObj: any, index: number) => {
                const randomNumber = Math.random()
                const { orderDetails: { items = [], orderFulfillmentStatus = '' } = {}, customerDetails: { shippingAddress = '' } = {} } = itemObj ?? {};
                const status = orderFulfillmentStatus;
                return <div className="w-max" onClick={(e) => {
                    e.stopPropagation()
                }} >
                    {/*// @ts-ignore */}
                    <div className='flex' data-tip data-tooltip-id={`${randomNumber}`} style={{ alignItems: 'center', height: '50px' }}>
                        <div>
                            <TextStyle>
                                {`${items?.length}`} {items?.length > 1 ? 'items' : 'item'}
                            </TextStyle>
                        </div>
                        <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
                            <DownIconTable />
                        </div>
                    </div>
                    <ReactTooltip id={`${randomNumber}`} place='bottom-start' border={'1px solid #EAEAEA'} opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

                        <div className="h-fit text-clear max-w-fit" onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <div className='max-w-60 text-wrap'>{shippingAddress || ''}</div>
                            <div>{items?.map((item: any) => (
                                <>
                                    <div className='text-itemsColor font-bold'>{item?.name}  x{item?.qty}</div>
                                    <div className={`ml-8 -mt-1 ${status === "Unfulfilled" ? 'text-pendingRed' : 'text-paidGreen'}`}>
                                        <ul className='list-disc text-xl'>
                                            <li>
                                                <span className='text-clear text-xs relative'>{status}</span>
                                            </li>
                                        </ul>
                                    </div>

                                </>)
                            )}
                            </div>
                        </div>
                    </ReactTooltip>
                </div>
            }
        },
        {
            Header: 'Discount Total',
            disableSortBy: true,
            accessor: (item: any) => {
                const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
                return (
                    <div className="w-max min-w-24">
                        <TextStyle>
                            {discountAmount ? `₹ ${` `}${discountAmount.toFixed(2)}` : '-'}
                        </TextStyle>
                    </div>
                )

            }
        },
        {
            Header: 'Discount Name',
            disableSortBy: true,
            accessor: (item: any) => {
                const { orderDetails: { discountCoupon = '' } = {} } = item ?? {};
                return (
                    <div className="flex items-center w-max">
                        <TextStyle className="w-max">
                            {discountCoupon ? `${discountCoupon}` : '-'}
                        </TextStyle>
                    </div>
                )

            }
        },
        {
            Header: 'Action',
            disableSortBy: true,
            accessor: (rowData: any) => {
                //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
                return (
                    <div className="w-max min-w-24">
                        {(((rowData?.orderDetails?.paymentMethod === "prepaid" && sellerDomain !== "cosmix.in") &&
                            (rowData.orderDetails?.gateway?.toLocaleLowerCase() === "cashfree" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "razorpay" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "billdesk" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "phonepe" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "snapmint" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "payu" ||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "easebuzz" || 
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "simpl"||
                                rowData.orderDetails?.gateway?.toLocaleLowerCase() === "potlee"
                            )) || rowData?.orderDetails?.paymentMethod === "partial_paid") ?
                            <RefundButtonTw className="mr-3" onClick={() => refundHandler(rowData)}><RefundIcon /> <span>Refund</span></RefundButtonTw> :
                            "-"
                        }
                    </div>
                )

            }
        },

    ]

    return (
        <>
            <div className="flex flex-col gap-4">
                <div className='flex gap-8 flex-wrap'>
                    <AmountOfCard heading={'Sales'} amount={ordersData?.totalSale ?? 0} />
                    <TotalCard heading={'Total Orders'} amount={ordersData?.totalOrders ?? 0} />
                    {/* <AOVCard heading={'AOV'} amount={ordersData?.totalOrders ?? 0} /> */}
                    {/* <WalletCard heading={'Prepaid'} amount={20} /> */}

                </div>
                {/* <button className='bg-codOrange' onClick={()=>{SendNotifications('info','This is info')}}>Hello</button> */}
                <div>
                    <TableContainerTw>
                        <FlexRowTw className='justify-between'>
                            <FlexRowTw>
                                <CustomReactInput addOnBefore={modifiedAddOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={channel==='woocommerce'? 'Platform ID':'Order ID'} inputValue={inputValue} setSearchTerm={setSearchTerm} />
                            </FlexRowTw>


                            <div className="flex items-center gap-3 font-bold py-4 h-8 w-max ml-8">
                                <CustomDownloadButton onClick={() => {
                                    setIsDataDownloading(true)
                                    selectedRows?.length ?
                                        handleMultipleDownload(fromDate, toDate, selectedRowKeys.join(','), loggedInFromAdmin, setIsDataDownloading) :
                                        handleAllDownload(fromDate, toDate, searchKey, searchTerm, rtoFilter, deliveryFilter, paymentFilter, source, loggedInFromAdmin, setIsDataDownloading)
                                }} className='items-center' disabled={isDataDownloading}>
                                    {
                                        !isDataDownloading ? <>
                                            <span className=''>
                                                <ExportIcon />
                                            </span>
                                            <span>{selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}</span></> : <><div className='loader' /></>}
                                </CustomDownloadButton>
                            </div>
                            {/* <div className='flex gap-2'> */}
                            {/* <CustomButton className='pl-2 w-20' onClick={() => { setDisplayFilter(!displayFilter) }}><div className='flex'><FilterIcon /><span>Filter</span></div></CustomButton> */}
                            {/* {displayFilter && <div className='absolute w-96 top-96 right-4 rounded-lg'><Filter filterData={Filterdata} setFilter={setFilter} setDisplayFilter={setDisplayFilter} /></div>} */}
                            {/* <CustomButton className="pl-2 w-20" onClick={() => { setDisplaySort(!displaySort) }}><div className='flex pl-2'><div className='mr-2 mt-1'><UpDownIcon /></div><span>Sort</span></div></CustomButton> */}
                            {/* {displaySort && <div className='absolute top-96 right-6'>
                                    <SortByCard TagsValue={TagsValue} setDisplaySort={setDisplaySort} setSortValue={setSortValue} sortValue={sortValue} setDefaultSortBy={setDefaultSortBy} defaultSortBy={defaultSortBy} displayFilter={setDisplayFilter} />
                                </div>} */}
                            {/* </div> */}
                        </FlexRowTw>

                        <CustomReactTable
                            loading={isLoading}
                            data={tableData || []}
                            pageNumber={pageNumber}
                            columns={col}
                            setPageNumber={setPageNumber}
                            totalPages={ordersData?.total}
                            checkboxes={true}
                            onSelectAll={onSelectAll}
                            pageSize={20}
                            selectedRowsKeys={selectedRowKeys}
                            defaultSortBy={defaultSortBy}
                            newState={viewMoreState}
                            handleRowSelect={handleRowSelect}
                            isChecked={isChecked}
                            loadingRows={10}
                            setHoveredRowIndex={setHoveredRowIndex}
                        />
                    </TableContainerTw>

                </div>
                <RefundModal
                    isRefundStatusModalOpen={isRefundStatusModalOpen}
                    setIsRefundStatusModalOpen={setIsRefundStatusModalOpen}
                    activeKey={refundModalActiveKey}
                    refundDetails={refundDetails}
                    setRefundDetails={setRefundDetails}
                />
                {/* <div className="notification-box flex flex-col items-center justify-center fixed  bottom-20 w-full z-50 p-3"/> */}
            </div>
        </>
    )
}

export default OrdersTabNew
