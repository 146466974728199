import React, { useState } from "react";
import ProductSearch from "./ProductSearch";
import { Radio, Row, Col, Form, RadioChangeEvent, Select, Space } from "antd";
import { CustomInput } from "../styled";

const BundlConfig = ({
  bundleItem,
  setBundleItem,
  setSelectTypes,
  form,
  sellerConfigValues,
  discountType,
  selectTypes,
}: any) => {
  const { Option } = Select;
  const [value, setValue] = useState<string | number>("percent");
  const [discountOfferValue, setDiscountOfferValue] = useState('offerDiscount')
  const Regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  const percentRegex = new RegExp(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?)(\.[0-9]{1,2})?$)/);
  const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ configValue: "", bundleProductType: null });
    setSelectTypes({
      ...selectTypes,
      bundleConfigType: e.target.value,
      bundleType: "",
      configType: e.target.value
    });
    setBundleItem([]);
    setValue(e.target.value);
  };

  const onChangeOfferDiscount = (e: RadioChangeEvent) => {
    form.setFieldsValue({ configValue: "", bundleProductType: null });
    setSelectTypes({
      ...selectTypes,
      bundleConfigType: 'flat',
      bundleType: "",
      offerBundleDiscountType: e.target.value,
      configType: 'flat'
    });
    setBundleItem([]);
    setDiscountOfferValue(e.target.value);
  };

  return (
    <div>
      <Form.Item name="offerBundleDiscountType" initialValue={"offerDiscount"}>
        <Radio.Group onChange={onChangeOfferDiscount} value={discountOfferValue}>
          <Radio value={"offerDiscount"}>Offer a Discount</Radio>
          <Radio value={"offerDiscountFlat"}>Offer Bundle at a Flat Value</Radio>
        </Radio.Group>
      </Form.Item>

    {selectTypes?.offerBundleDiscountType === "offerDiscount" ? 
        <Form.Item name="configDiscountType" initialValue={"flat"}>
        <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
            <Radio value={"flat"}>Flat</Radio>
            <Radio value={"percent"}>Percent</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    : ''
    }
      

      <Row>
        <Col span={12}>
          <Form.Item
            label={
              selectTypes?.configType === "flat" || selectTypes?.configType === "flatValue" ? "Amount" : "Percentage"
            }
            name="configValue"
            rules={[
              {
                required: true,
                pattern:
                  selectTypes?.configType === "percent" ? percentRegex : Regex,
                message: "Please enter the valid value",
              },
            ]}
          >
            <CustomInput
              maxLength={selectTypes?.configType === "flat" ? 10 : 4}
              placeholder="Enter the value"
              style={{ width: 225 }}
            />
          </Form.Item>
        </Col>


        <Col span={12}>
            <Form.Item
              name="bundleProductType"
              label="Form"
              rules={[
                {
                  // required: true,
                  message: "please select form type",
                },
              ]}
              initialValue = {selectTypes.bundleType}

            >
              <Select
                style={{ width: "100%" }}
                placeholder="---select type---"
                onChange={(value: string) => {
                  if (value) {
                    setSelectTypes({
                      ...selectTypes,
                      bundleType: value,
                    });
                    setBundleItem([]);
                  }
                }}
              >
                <Option value="product">Product</Option>
                <Option value="collection">Collection</Option>
              </Select>
            </Form.Item>
          </Col>

        {/* {selectTypes?.bundleConfigType === "flat" ? (
          
        ) : (
          ""
        )} */}


      {selectTypes.bundleType === "collection" && 
            <Col span={12}>
            <Form.Item
                label="Quantity"
                name="bundleQuantity"
                rules={[
                {
                    required: true,
                    pattern: quantityRegex,
                    message: "Please enter the valid value",
                },
                ]}
            >
              <CustomInput
                maxLength={6}
                placeholder="Enter the Quantity"
                style={{ width: 225 }}
                />
            </Form.Item>
            </Col>
        }
      </Row>

      {selectTypes?.offerBundleDiscountType === "offerDiscount" ? (
        <ProductSearch
          selectedItems={bundleItem}
          setSelectedItems={setBundleItem}
          type={selectTypes?.bundleType}
          discountType={discountType}
          sellerConfigValues={sellerConfigValues}
          bundleKey = {Math.random() * 1001}
        />
      ) : (
        <ProductSearch
          selectedItems={bundleItem}
          setSelectedItems={setBundleItem}
          type={selectTypes?.bundleType}
          discountType={discountType}
          sellerConfigValues={sellerConfigValues}
          bundleKey = {Math.random() * 1001}
        />
      )}
    </div>
  );
};

export default BundlConfig;
