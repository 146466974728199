import { Button } from "antd";
import { PickrrButton } from "components/UIElements";
import React from "react";
import { CustomModal, ErrorMessageContainer, FooterContainer } from "./styled";


const ErrorModal = ({isVisible,setIsVisible,setIsEdit,setEditOrderData,setSelectedItems,setVariantList}: any)=>{

    const handleOnclick = ()=>{
        setEditOrderData({})
        setSelectedItems([])
        setVariantList([])
        setIsEdit(false)
        setIsVisible(false)
    }

    return(
        <>
        {/*//@ts-ignore */}
        <CustomModal
        visible = {isVisible}
        title = ''
        onCancel={()=>{
            setIsVisible(false)
        }}
        footer = {false}
        >
            <ErrorMessageContainer>
                <img src="https://d10srchmli830n.cloudfront.net/1681896274289_5ac4f74a-42a9-4946-9c71-a8d227bbf80e_States---Popups-icons.svg" alt="icon" style={{height :'56px', width : '56px', marginBottom : '30px'}}/>
                <span style={{fontSize : '22px', lineHeight : '27px',color : '#000B34', fontWeight : 700, marginBottom : '10px'}}>
                Unsaved Changes
                </span>
                <span style={{fontSize : '18px', lineHeight : '24px', fontWeight : 600, marginBottom : '10px'}}>
                Do you really want to leave without saving ?
                </span>
                <FooterContainer>
                    <Button 
                    onClick={()=>{
                        setIsVisible(false)
                    }}
                    color="cancel" 
                    style={{border : '1px solid #263F97', height : '40px', borderRadius : '8px',marginRight : '18px', width : '130px', color : '#000B34'}}
                    >
                        Cancel
                    </Button>
                    <Button 
                    onClick={()=>handleOnclick()}
                    color="checkout-blue" 
                    style={{width : '130px', height : '40px', borderRadius : '8px',background : '#F05353', border : '0px',color : '#FFFFFF', fontWeight : 600}}
                    >
                        Leave 
                    </Button>
                </FooterContainer>
            </ErrorMessageContainer>

        </CustomModal>
        </>
    )
}

export default ErrorModal