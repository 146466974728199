import { Col, Row } from "antd";
import Header from "components/CommonComponents/Header";
import { PickrrCard } from "components/UIElements";
import { Container, CustomForm, PickrrButton } from "../GeneralSettings/style";
import { ChannelText, CustomChannelIcon, ImgBox } from "./style";
import { useNavigate} from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getGeneralDetails } from "../GeneralSettings/script";
const ManageChannel = () => {
  const history = useNavigate();
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );
  const channelList = [
    {
      name: "Shopify",
      img_url:
        "https://d10srchmli830n.cloudfront.net/1654599647044_d1406ada-ba10-4616-83f1-66ce4ec0ba4f_Channel.png",
      path: "/settings/manage-channel/shopify",
    },
    {
      name: "Woo-Commerce",
      img_url:
        "https://d10srchmli830n.cloudfront.net/1654599696480_eafba715-5f50-4272-b76f-814a818a6a0c_WooCommerce_logo-1.png",
      path: "/settings/manage-channel/woocommerce",
    },
  ];
  return (
    <Container>
      <Header title="Channel Integration" />
      <Row gutter={[16, 16]}>
        {channelList.map((data, index) => {
          return(<Col key={index} xl={5} lg={5} md={12} sm={12} x={24}>
          <CustomChannelIcon onClick={()=>history(data.path)}>
              <ImgBox>
            <img
              height="70px"
              src={data.img_url}
            />
            </ImgBox>
            <ChannelText>
            
            {data.name}
            </ChannelText>
          </CustomChannelIcon>
        </Col>);
        })}
        
      </Row>
    </Container>
  );
};
export default ManageChannel;
