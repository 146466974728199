import React from "react";
import '../Home/style.css'
const Stepper = ({ steps, currentStep,setCurrentStep}: any) => {
    return (
        <div className="stepper px-4">
        {steps.map((step: any, index: any) => (
            <div key={index} className={`step-container ${currentStep === index ? 'active' : ''}`}>
                <div className="step">
                    <div className="step-circle cursor-pointer" onClick={()=>{setCurrentStep(index)}}>
                        <span className="flex flex-col">
                            <span style={{ fontSize: '8px' }}>Step</span>
                            <span style={{ fontSize: '12px',marginTop:'-3px' }}>{index + 1}</span>
                        </span>
                    </div>
                    <div className="step-label w-max">
                        {step.label}
                    </div>
                    <div style={{ fontSize: '10px' }} className="text-subtextBrown max-w-32">
                        {step.sublabel}
                    </div>
                </div>
                {/* {index < steps.length - 1 && <div className="connector"/>} */}
            </div>
        ))}
    </div>
    );
}
export default Stepper