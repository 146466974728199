import { Col, DatePicker, Divider, Modal, Row, Select, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CrossButtonModal, DateContainer, DatePickerContainer, GatewayContainer, ModalContainer, ModalTitle, SelectContainer } from "./style";
import { FlexContainer } from "components/UIElements";
import {InfoCircleOutlined ,CaretUpFilled, CaretDownFilled,CloseOutlined} from '@ant-design/icons';
import { fetchOldRatesDetail, fetchRatesDetail } from "./scrpit";
import { useQuery } from "react-query";
import { EmptyViewContiner, Heading, SubHeading } from "components/UIElements/EmptyView/styled";
import { PricingButton } from "components/MasterLayout/style";
import moment from "moment";
import { DataContext } from "context/DataProvider";

const {Option} = Select;
const PricingModalNew = ({isViewPricingModalVisible,setIsViewPricingModalVisible}) => {

    const [viewOldPricing, setViewOldPricing] = useState(false)
    const [prevDateValue, setPrevDateValue] = useState(moment())
    const [pricingData, setPricingData] = useState([])
    const [pricingDataOld, setPricingDataOld] = useState([])
    const {sellerId, sellerDomain} = useContext(DataContext)
    const [paymentGateway, setPaymentGateway] = useState('DECENTRO')
    const [paymentMode, setPaymentMode] = useState('UPI')

    const urlSearchParams = new URLSearchParams(window.location.search);  
    const token = urlSearchParams.get('token');  

    const domainName = sellerDomain;
    const { data : ratesData, isLoading, isError } = useQuery(
        ['rateDetailsNew', { domainName }],
        fetchRatesDetail,
        {enabled: !token}
      );  

      const { data : ratesDataOld,isLoading : isLoadingOldPricing,isError: isErrorOldPricing } = useQuery(
        ['rateDetailsOld',{prevDateValue,sellerId,paymentGateway,paymentMode}],
        fetchOldRatesDetail
      );  

      useEffect(()=>{
        if(ratesData){
            const ConsolidatedData = [...(ratesData?.COD?.length > 0  ? ratesData?.COD : []) ,...(ratesData?.prepaid?.length > 0 ? ratesData?.prepaid : []),...(ratesData?.partial_paid?.length > 0 ? ratesData?.partial_paid : [])]
            const AllDates = ConsolidatedData.map((details)=>{
                return details?.active_from.concat(`:${details?.active_till}`)
            })
            const uniquedates = [...new Set(AllDates)]
            const newArray = uniquedates.map((Date)=>{
                const BothDate = Date.split(':')
                return {
                    dateParam : `#${BothDate[0]} to ${BothDate[1]}`,
                    data : ConsolidatedData.filter((data) =>
                        data?.active_from === BothDate[0] && data?.active_till === BothDate[1]
                    )}
            })
            setPricingData(newArray)
        }
        else{
            setPricingData([])
        }

      },[ratesData])

    return(
        <Modal
        // title={<ModalTitle>Review pricing</ModalTitle> }
        visible = {isViewPricingModalVisible}
        onCancel={()=>{
            setIsViewPricingModalVisible(false)
        }}
        width={700}
        footer={null}
        header = {null}
        closable = {false}
        >
            <CrossButtonModal>
                <CloseOutlined onClick={()=>{
                    setIsViewPricingModalVisible(false)
                }}/>
            </CrossButtonModal>
            <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '10px'}}>
                <FlexContainer style={{fontSize : '24px',fontWeight : '600'}}>
                    <img
                    src="https://d10srchmli830n.cloudfront.net/1687765075599_fcd51b57-a6fb-4369-96fc-4a1728817a2a_credit-card-1.svg"
                    alt="Pricing"
                    style={{marginRight : '5px'}}
                    />
                    Pricing
                </FlexContainer>
                <div style={{display : 'flex'}}>
                <FlexContainer 
                style={{fontWeight : 600, fontSize : '14px', cursor : 'pointer'}}>
                   <PricingButton
                   style={{height : '32px'}}
                    onClick={() => {
                        if(viewOldPricing){
                            setViewOldPricing(false)
                        }
                        else{
                            setViewOldPricing(true)
                        }
                    }}
                    >
                       {viewOldPricing ? 'View current Pricing' : 'View Old Pricing'}
                    </PricingButton>
                </FlexContainer>
                <FlexContainer onClick={()=>{
                    window.open('https://reverse-qc.s3.amazonaws.com/be56-0242ac120002-fastrr-pricing-tnc.pdf')
                }} 
                style={{fontWeight : 600, fontSize : '14px', cursor : 'pointer'}}>
                    <InfoCircleOutlined  style={{marginRight : '3px'}}/>
                    T&C
                </FlexContainer>
                </div>
            </Row>
            {!viewOldPricing ?
                <>
                {!isLoading ?
                <>
                <div style={{fontWeight : 500, fontSize : '16px',marginBottom : '15px',color: 'var(--text, #0C3784)'}}>
                Mentioned below are <b>commissioned rates</b> that will be charged on every order placed from <b>fastrr checkout</b>:
                </div>

                {/* <Row style={{display : 'flex'}}> */}
                { pricingData?.length > 0 ?
                    pricingData.map((pricingDetail)=>{
                        return(
                        <Col span={24} style={{display : 'flex',border : '1px solid #EDEBEB', borderRadius : '6px', padding : '10px',flexDirection : 'column', marginBottom : '20px'}}>
                            <DateContainer>
                            {pricingDetail?.dateParam}
                            </DateContainer>
                            {pricingDetail?.data?.map((pricingRowData,index)=>{
                                return(
                                    <>
                                    {pricingRowData?.payment_method === "CASH_ON_DELIVERY" ? 
                                    <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                        <Col span={5}>
                                            <GatewayContainer>
                                            COD
                                            </GatewayContainer>
                                        </Col>
                                        <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                            <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                            <Col span={7} >
                                            </Col>
                                            <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                            <img 
                                                src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                alt="Payment Icon"
                                                style={{height : '22px', marginRight : '4px'}}
                                                />
                                                {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                            </Col>
                                            <Col span={9} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                {pricingRowData?.max_cap ?
                                                    <Col span={24}>
                                                    <CaretUpFilled style={{color : '#0C3784'}}/>
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Ceiling: 
                                                    </span>
                                                    {`₹${pricingRowData?.max_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                                {pricingRowData?.min_cap ?
                                                    <Col span={24}>
                                                    <CaretDownFilled style={{color : '#0C3784'}} />
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Floor: 
                                                    </span>
                                                    {`₹${pricingRowData?.min_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    pricingRowData?.payment_method === "PARTIAL_PAID" ? 
                                    <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                        <Col span={5}>
                                            <GatewayContainer>
                                            PARTIAL PAID
                                            </GatewayContainer>
                                        </Col>
                                        <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                            <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                            <Col span={7} >
                                            </Col>
                                            <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                            <img 
                                                src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                alt="Payment Icon"
                                                style={{height : '22px', marginRight : '4px'}}
                                                />
                                                {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                            </Col>
                                            <Col span={9} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                {pricingRowData?.max_cap ?
                                                    <Col span={24}>
                                                    <CaretUpFilled style={{color : '#0C3784'}}/>
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Ceiling: 
                                                    </span>
                                                    {`₹${pricingRowData?.max_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                                {pricingRowData?.min_cap ?
                                                    <Col span={24}>
                                                    <CaretDownFilled style={{color : '#0C3784'}} />
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Floor: 
                                                    </span>
                                                    {`₹${pricingRowData?.min_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    pricingRowData?.payment_gateways?.map((payment_gateways,index)=>{
                                        return(
                                            <>
                                            <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                                <Col span={5}>
                                                    <GatewayContainer>
                                                    {payment_gateways}
                                                    </GatewayContainer>
                                                </Col>
                                                <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                                    <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                                    <Col span={7} style={{display : 'flex',fontWeight : 500, fontSize : '16px',lineHeight : '20px',color : '#0C3784',alignItems : 'flex-start',flexDirection : 'column', overflow: 'hidden', whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                                        {pricingRowData?.payment_modes.map((payment_mode)=>{
                                                            return(
                                                                <div style={{display : 'flex',flexDirection : 'row',marginBottom : '10px',wordBreak:'break-all'}}>
                                                                <img 
                                                                src="https://d10srchmli830n.cloudfront.net/1687771744122_9425893f-22eb-414f-bd63-7b86989d68e2_Group.svg"
                                                                alt="Payment Icon"
                                                                style={{height : '18px', marginRight : '4px'}}
                                                                />
                                                                {payment_mode.toUpperCase()}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </Col>
                                                    <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                                    <img 
                                                        src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                        alt="Payment Icon"
                                                        style={{height : '22px', marginRight : '4px'}}
                                                        />
                                                        {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                                    </Col>
                                                    <Col span={9} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                        {pricingRowData?.max_cap ?
                                                            <Col span={24}>
                                                            <CaretUpFilled style={{color : '#0C3784'}}/>
                                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                            Ceiling: 
                                                            </span>
                                                            {`₹${pricingRowData?.max_cap} per order  `}
                                                            </Col>
                                                            : null
                                                        }
                                                        {pricingRowData?.min_cap ?
                                                            <Col span={24}>
                                                            <CaretDownFilled style={{color : '#0C3784'}} />
                                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                            Floor: 
                                                            </span>
                                                            {`₹${pricingRowData?.min_cap} per order  `}
                                                            </Col>
                                                            : null
                                                        }
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {(index !== pricingRowData?.payment_gateways?.length - 1 ) &&
                                            <Divider />
                                            }
                                            </>
                                        )
                                    })
                                    }
                                    {(index !== pricingDetail?.data?.length - 1 ) &&
                                    <Divider />
                                    }
                                    </>
                                )
                            })
                            }
                        </Col>
                        )
                    })
                    :
                    <EmptyViewContiner>
                    <img 
                        src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
                        alt="empty-data"
                    />
                    <Heading >
                        No Data Available.
                    </Heading>
                    <SubHeading>
                        Looks likes there is no data available , Please try again later. 
                    </SubHeading>
                </EmptyViewContiner>
                }  
                {/* </Row> */}
                </> :
                <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '20px'}}>
                <Spin />
                </div>
                }
                </>
                :
                <>
                <div style={{fontWeight : 500, fontSize : '16px',marginBottom : '15px',color: 'var(--text, #0C3784)'}}>
                Please select the <b>date, payment gateway and payment mode</b> for which you want to see the <b>applicable pricing.</b>
                </div>
                <Row gutter={[16,16]} style={{display : 'flex',justifyContent : 'center'}}>
                <Col span={8} style={{marginBottom : '10px'}}>
                    <DatePickerContainer>
                    <DatePicker 
                    suffixIcon={<img src="https://d10srchmli830n.cloudfront.net/1690789249044_01c397b5-9ce0-4c0b-97bf-bb22314f1d9c_calendar.svg"/>}
                    onChange={(date, dateString) => setPrevDateValue(date)}
                    value={moment(prevDateValue)}
                    allowClear = {false}
                    disabledDate={(current) => {
                        return current && current > moment();
                      }}
                    />
                    </DatePickerContainer>
                </Col>
                <Col span={8} style={{marginBottom : '10px'}}>
                    <SelectContainer>
                        <Select 
                        value={paymentGateway}
                        onChange={(value)=>{
                            setPaymentGateway(value)
                        }}
                        >
                            <Option value="DECENTRO">Decentro</Option>
                            <Option value="SETU">Setu</Option>
                            <Option value="CASHFREE">Cashfree</Option>
                            <Option value="RAZORPAY">Razorpay</Option>
                            <Option value="BILLDESK">Billdesk</Option>
                            <Option value="CREDPAY">CREDPAY</Option>
                            <Option value="EASEBUZZ">EASEBUZZ</Option>
                            <Option value="PAYU">PAYU</Option>
                            <Option value="CASH_ON_DELIVERY">COD</Option>
                            <Option value="PARTIAL_PAID">Partial paid</Option>
                            <Option value="SNAPMINT">Snapmint</Option>
                            <Option value="PHONEPE">Phonepe</Option>
                        </Select>
                    </SelectContainer>
                </Col>
                <Col span={8} style={{marginBottom : '10px'}}>
                    <SelectContainer>
                        <Select
                        value={paymentMode}
                        onChange={(value)=>{
                            setPaymentMode(value)
                        }}
                        >
                            <Option value="UPI">UPI</Option>
                            <Option value="Credit/Debit Card">Credit/Debit Card</Option>
                            <Option value="Net Banking">Net Banking</Option>
                            <Option value="Wallets">Wallets</Option>
                            <Option value="Pay Later">Pay Later</Option>
                            <Option value="Pay with EMI">Pay with EMI</Option>
                            <Option value="Rewards/Loyalty programs">Rewards/Loyalty programs</Option>
                            <Option value="Credpay">Credpay</Option>
                        </Select>
                    </SelectContainer>
                </Col>
                </Row>
                {!isLoadingOldPricing ? 
                    ratesDataOld?.length > 0 ?
                        ratesDataOld.map((pricingDetail)=>{
                            return(
                                <Col span={24} style={{display : 'flex',border : '1px solid #EDEBEB', borderRadius : '6px', padding : '10px',flexDirection : 'column', marginBottom : '20px'}}>
                                    <DateContainer>
                                    {`#${pricingDetail?.activeFrom} - ${pricingDetail?.activeTill}`}
                                    </DateContainer>
                                    <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                    <Col span={9} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                    <img 
                                        src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                        alt="Payment Icon"
                                        style={{height : '22px', marginRight : '4px'}}
                                        />
                                        {pricingDetail?.commissionType === "FLAT" ? `₹${pricingDetail?.flatCharge} per orders` : `${pricingDetail?.percentageRate}% of the total order`}
                                    </Col>
                                    <Col span={9} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                        {pricingDetail?.maxCap ?
                                            <Col span={24}>
                                            <CaretUpFilled style={{color : '#0C3784'}}/>
                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                            Ceiling: 
                                            </span>
                                            {`₹${pricingDetail?.maxCap} per order  `}
                                            </Col>
                                            : null
                                        }
                                        {pricingDetail?.minCap ?
                                            <Col span={24}>
                                            <CaretDownFilled style={{color : '#0C3784'}} />
                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                            Floor: 
                                            </span>
                                            {`₹${pricingDetail?.minCap} per order  `}
                                            </Col>
                                            : null
                                        }
                                    </Col>
                                    </Row>
                                </Col>
                            )
                        })
                        :
                        <EmptyViewContiner>
                        <img 
                            src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
                            alt="empty-data"
                        />
                        <Heading >
                            No Data Available.
                        </Heading>
                        <SubHeading>
                            Looks likes there is no data available , Please try again later. 
                        </SubHeading>
                    </EmptyViewContiner>
                : 
                <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '20px'}}>
                <Spin />
                </div>
                } 

                </>
            }

        </Modal>
    )
}

export default PricingModalNew