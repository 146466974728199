import Form from 'UiComponents/CustomForm/Form';
import { CustomSelect } from 'UiComponents/Select/dropdownNew';
import { CustomDatePicker } from 'components/UIElements';
import { CrossIcon, ToastErrorCross, ToastSuccessTick } from 'images/icons';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import getControls from './controls';
import { useLocation } from "react-router-dom";
import axiosInstance from 'HTTP/Http';
import { downloadFile } from 'components/UIElements/function';
import { toast } from 'react-toastify';
import { useQueryClient } from "react-query"
import { CustomDownloadButton } from 'pages/Reports/styles';



const GenerateReportModal = ({ setShowGenerateModal,setPageNumber}: any) => {
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [responseErrors, setResponseErrors] = useState<any>({});

  const queryClient = useQueryClient();

  const { state }: any = useLocation();

  const formProps = useForm();
  const { handleSubmit, formState: { errors } } = formProps;

  const controls = getControls();

  const handleFormSubmit = handleSubmit((formData) => {
    setResponseErrors({});
    onSubmit(formData, state);
  });

  const onSubmit = async (formData: any, state: any) => {
    setIsResponseLoading(true);
    const userToken = localStorage.getItem("userManagementToken")

    const emails = formData.emails?.split(/,\s*/).map((email: string) => email.trim());

    const postData = {
      emails: emails,
    }
    const from = formData?.date_range?.[0]?.format("YYYY-MM-DD");
    const to = formData?.date_range?.[1]?.format("YYYY-MM-DD");
    const source = state?.source || 'all';

    const queryParams = { from, to, source }

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/${formData?.report_type?.value}`, postData, {
        headers: {
          "User-Token": userToken,
        },
        params: {
          ...queryParams,
        }
      })
      setIsResponseLoading(false);

      toast.success('Report generated successfully', {
        icon: ToastSuccessTick
      });
      setShowGenerateModal(false);
      setPageNumber(1);
      queryClient.invalidateQueries('reportsData');

    } catch (error) {
      setIsResponseLoading(false);
      setResponseErrors(error?.response?.data);
      toast.error(error?.response?.data?.message, {
        icon: ToastErrorCross
      })
      setIsResponseLoading(false);
    }
  }


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
      <div className="fixed inset-0 bg-black opacity-50" onClick={() => { setShowGenerateModal(false) }} />
      <div className="flex flex-col relative z-50 bg-refundWhiteBg rounded-lg  p-4 h-fit-content w-[480px]">
        <div className='flex justify-between items-center'>
          <div className="text-lg w-2/3  mt-1 mb-3 ml-1" style={{ fontWeight: '700' }}>Generate New Report</div>
          <div className='cursor-pointer h-5 w-5 mb-3 flex items-center justify-center' onClick={() => { setShowGenerateModal(false); setResponseErrors({}) }}>
            <CrossIcon />
          </div>
        </div>

        <form className='ml-1'>
          <Form controls={controls} formProps={formProps} />

          {responseErrors?.data && <div className='text-redColor mt-4 text-center'>
            <div className='break-all'>[{responseErrors?.data?.join(', ')}]</div>
             <span>{responseErrors?.message}</span>
          </div>}

          <div className='flex justify-center mt-4 mb-2'>
            <CustomDownloadButton className='max-w-10' type='submit' onClick={handleFormSubmit} disabled={isResponseLoading}>Generate Report</CustomDownloadButton>
          </div>

        </form>
      </div>

    </div>
  )
}

export default GenerateReportModal