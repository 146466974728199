import React,{ useState,useEffect } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { CSS } from "@dnd-kit/utilities";
import moment from "moment";
import { CustomTable, FlexBox, FlexCol, FlexRow } from "../styled";
import { Switch, Tooltip, notification } from "antd";

import {
  DateTimeText,
  DiscountDescription,
  DiscountName,
  FlexStartContainer,
  ListContainer,
  TagsDiv,
} from "./styled";

import { IconContainer,PickrrButton } from "components/UIElements";
import { updateDiscount } from "../CreateDiscountModal/script";
import { deleteDiscountData, getSearchData } from "components/Settings/GeneralSettings/script";

import {
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  EditOutlined,
} from '@ant-design/icons';

const DiscountTable = ({
  isLoading,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  dataSource,
  isSortVisible,
  handleEdit,
  suggestedDataSource,
  setSuggestedDataSource,
  handleSave,
  setIsShow,
  isShow,
  sellerConfigValues,
  searchTerm,
  setSearchTerm,
  setInputValue
}) => {
  const queryClient = useQueryClient();
  const [displayData,setDisplayData]=useState(
    isSortVisible
      ? suggestedDataSource?.discounts || []
      : dataSource?.discounts || []
  )
  const[totalPages,setTotalPages]=useState(dataSource?.discountCount ? dataSource?.discountCount : [])

  const deleteCoupon=useMutation(deleteDiscountData,{
    onMutate:(values)=>{
      setSearchTerm("")
      setInputValue("")
      return {id:1}
    }
    ,
    onError:()=>{
      notification.error({message:"failed to delete coupon"})
    },
    onSuccess:()=>{
      queryClient.invalidateQueries(["fetchDiscountData"]);
      queryClient.invalidateQueries(["fetchSuggestedDiscountData"]);
      // queryClient.invalidateQueries(["getSearchData"]);
      notification.success({message:"Successfully Deleted Coupon "})
    }
  })
  
  const updateCoupon = useMutation(updateDiscount, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: () => {
      notification.error({ message: "Something went wrong." });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchSuggestedDiscountData"]);
      queryClient.invalidateQueries(["fetchDiscountData"]);
      notification.success({ message: "successfully updated coupon" });
    },
  });

  const{data:searchData,isLoading:newIsLoading}=useQuery(
    ['getSearchData',sellerConfigValues?.seller_id,searchTerm],
    getSearchData,

  )

  useEffect(() => {
    setSearchTerm("")
    setInputValue("")
  },[isSortVisible])
  

  useEffect(() => {
    if (searchTerm && searchData){
      setDisplayData([searchData])
      setTotalPages(1)
    }
    else if (searchTerm && !searchData){
      setDisplayData([])
      setTotalPages(0)
    }
    else{
      setDisplayData(
        isSortVisible
      ? suggestedDataSource?.discounts || []
      : dataSource?.discounts || []
      )
      setTotalPages(
        isSortVisible
        ? suggestedDataSource?.discountCount || []
        : dataSource?.discountCount || []
      )
    }
  },[searchData, dataSource?.discounts,searchTerm, isSortVisible])
  
 

  const handleDelete=(id,couponCode)=>{
    deleteCoupon.mutate({id,couponCode})
  }
  const handleActiveCoupon = (record) => {
    if(record){
      const data = {
          id: record?.id,
          active: !record?.activeStatus,
          sellerId: sellerConfigValues?.seller_id,
          service: "fastrr",
          type: record?.category
      } 
      updateCoupon.mutate(data)
    }
  }

  const dateFormat = "DD-MM-YYYY";
  const timeFormat = "hh:mm A";

  const columns = [
    {
      key: "sort",
    },
    {
      dataIndex: "discountDetails",
      key: "discountDetails",
      width: 700,
      render: (data, record) => (
        <>
          <FlexStartContainer>
            <img
              src="https://d10srchmli830n.cloudfront.net/1648020195901_tabler_discount-2Discount.png"
              alt="discount-icon"
            />
            <DiscountName> {record.discountCode}</DiscountName>
          </FlexStartContainer>
          <ListContainer>
            <ul>
              <li>
                <DiscountDescription>
                  {record.description ? record.description : "N/A"}
                </DiscountDescription>
              </li>
            </ul>
          </ListContainer>
          <TagsDiv>
            <FlexBox>
              <div className="tags">
                <p>
                  {record.source?.charAt(0)?.toUpperCase() +
                    record.source?.slice(1)}
                </p>
              </div>
              <div className="tags">
                <p>
                  {record.discountType?.charAt(0)?.toUpperCase() +
                    record.discountType?.slice(1)}
                </p>
              </div>
              <div className="tags">
                <p>
                  {record.category?.charAt(0)?.toUpperCase() +
                    record.category?.slice(1)}
                </p>
              </div>
            </FlexBox>
          </TagsDiv>
        </>
      ),
    },
    {
      dataIndex: "timePeriod",
      key: "timePeriod",
      width: 300,
      render: (data, record) => (
        <>
          <DateTimeText style={{ marginBottom: "5px" }}>
            Time Period
          </DateTimeText>
          <DateTimeText>
            Start -
            <span>
              {record.validity?.startTime && moment(record.validity?.startTime).format("YYYY-MM-DD")} &nbsp;
              {record.validity?.startTime && moment(record.validity?.startTime).format("HH:mm")}
            </span>
          </DateTimeText>
          <DateTimeText>
            End -
            <span>
              {record?.validity?.endTime && moment(record.validity.endTime).format("YYYY-MM-DD")} &nbsp;
              {record?.validity?.endTime && moment(record.validity.endTime).format("HH:mm")}
              {/* {new Date(record.validity.endTime).toLocaleDateString()}&nbsp;(
              {moment(
                new Date(record.validity.endTime).toLocaleTimeString(),
                "hh:mm"
              ).format("LT")}
              ) */}
            </span>
          </DateTimeText>
        </>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (data, record) => (
        <FlexBox>
          <FlexCol
            style={{
              alignItems: "center",
              height: "85px",
              justifyContent: "space-between",
              marginTop: "13px",
            }}
          >
            <Switch
              defaultChecked={record.activeStatus}
              // checked={record.activeStatus}
              onChange={() => handleActiveCoupon(record)}
              key = {record?.id}
            />
            <FlexBox className="mb-16" style={{ justifyContent: 'center',marginLeft:'10px'}}>
            <Tooltip title="Edit">
              <IconContainer
                className="mr-12"
                onClick={() => handleEdit(record?.id)}
                style={{ background:"#e9ecf5",height:"40px",width:"40px",color:"#263f97",fontSize:"15px", margin: '-7px' }}
              >
                <EditOutlined />
              </IconContainer>
            </Tooltip>
            <Tooltip title="Delete">
                <IconContainer className="mr-12" style={{background:"#e9ecf5",height:"40px",width:"40px",color:"#263f97",fontSize:"15px", margin: '-7px' }} onClick={()=>{handleDelete(sellerConfigValues?.seller_id,record?.discountCode)}}
  >
                  <DeleteOutlined />
                </IconContainer>
            </Tooltip>
          </FlexBox>
          </FlexCol>
        </FlexBox>
      ),
    },
  ];
  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setIsShow(true);
      setSuggestedDataSource((previous) => {
        const activeIndex = previous.discounts.findIndex(
          (i) => i.key === active.id
        );
        const overIndex = previous.discounts.findIndex(
          (i) => i.key === over.id
        );

        const data = {
          ...previous,
          discounts: arrayMove(previous.discounts, activeIndex, overIndex),
        };
        return data;
      });
    }
  };

  let locale = {
    emptyText: <h3 style={{ margin: "10% auto" }}>No discounts found.</h3>,
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={
          isSortVisible
            ? suggestedDataSource?.discounts?.map((i) => i.key) || []
            : dataSource?.discounts?.map((i) => i.key) || []
        }
        strategy={verticalListSortingStrategy}
      >
        <CustomTable
          components={
            isSortVisible && {
              body: {
                row: Row,
              },
            }
          }
          locale={!isLoading && locale}
          rowClassName="rowClassName1"
          showHeader={false}
          dataSource={displayData}
          columns={columns}
          loading={isLoading || newIsLoading}
          pagination={
            !isSortVisible && {
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions : ['10', '20', '30', '40','50'], 
              total: totalPages,
              current: pageNumber,
              onChange: (page, size) => {
                setPageNumber(page);
                setPageSize(size);
              },
              position: ["bottomRight"],
            }
          }
          //   rowKey={(record) => console.log("record.key", record.key)}
        />
        {isSortVisible && suggestedDataSource?.discounts && isShow && (
          <PickrrButton
            style={{ marginBottom: "15px" }}
            onClick={() => handleSave(suggestedDataSource)}
            color="checkout-blue"
          >
            Save
          </PickrrButton>
        )}
      </SortableContext>
    </DndContext>
  );
};
export default DiscountTable;
