import React from "react";
const FormStep3 = ({ accountStatus }: any) => {
    const handleDemoClick = () => {
        return 
        // if (accountStatus === 'design completed') {
        //     {/*// @ts-ignore */ }
        //     HeadlessCheckout.InitiateDirectCheckout(
        //         "", "cart",
        //         [{
        //             variantId: 44370635423968,
        //             quantity: 1
        //         }],
        //         "", "", null)
        // }

    }
    return <>
        <div className="text-textGreyFont">
            <p className="font-bold text-lg text-black">Demo link</p>
            <p >Explore the demo link tailored specifically for your business needs.</p>
            <p className="font-semibold underline text-base mt-2" onClick={handleDemoClick}>Click to view</p>
            <p className="font-bold text-lg text-black mt-5">Go live approval</p>
            <p>Upon approval, we will implement the same checkout experience on your live website.</p>
        </div>
    </>
}
export default FormStep3