import React from 'react';
import {Row, Col, Input} from 'antd'
import {InputContainer} from '../../styled';


const DeliveryOptions  = ({deliveryOptionList, setDeliveryOptionList}) => {
    // console.log(customerEmailList, 'checking customer email list')
    const onChange = (e) =>{
        setDeliveryOptionList(() => e.target.value);
        console.log(e.target.value)
    }
    // setCustomerEmailList(() => e.target.value)
    return (
        <Row gutter={[16, 16]}>
            <Col>
                <InputContainer>
                    <Input
                        placeholder='Enter Delivery Options'
                        value={deliveryOptionList}
                        onChange={onChange}
                    />
                </InputContainer>
            </Col>
        </Row>
    )
}
export default DeliveryOptions;