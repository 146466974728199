import { Col, Divider, Form, Input, Modal, Row, Spin, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {DateContainer, GatewayContainer, ModalContentContainer, ModalTitle } from "./style";
import PickerButton from "antd/lib/date-picker/PickerButton";
import { PickrrButton } from "components/UIElements";
import { useQuery, useQueryClient } from "react-query";
import { fetchRatesDetail } from "./script";
import axios from "axios";
import { DataContext } from "context/DataProvider";
import {CaretUpFilled, CaretDownFilled} from '@ant-design/icons';
import { EmptyViewContiner, Heading, SubHeading } from "components/UIElements/EmptyView/styled";

const PricingModal = ({isPricingModalVisible,setIsPricingModalVisible,ratesData, isLoading, isError}) => {
    const {sellerId, IpAddress} = useContext(DataContext)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [pricingData, setPricingData] = useState([])

    const queryClient = useQueryClient();

    useEffect(()=>{
        if(ratesData){
            const ConsolidatedData = [...(ratesData?.COD?.length > 0  ? ratesData?.COD : []) ,...(ratesData?.prepaid?.length > 0 ? ratesData?.prepaid : []),...(ratesData?.partial_paid?.length > 0 ? ratesData?.partial_paid : [])]
            const AllDates = ConsolidatedData.map((details)=>{
                return details?.active_from.concat(`:${details?.active_till}`)
            })
            const uniquedates = [...new Set(AllDates)]
            const newArray = uniquedates.map((Date)=>{
                const BothDate = Date.split(':')
                return {
                    dateParam : `#${BothDate[0]} to ${BothDate[1]}`,
                    data : ConsolidatedData.filter((data) =>
                        data?.active_from === BothDate[0] && data?.active_till === BothDate[1]
                    )}
            })
            setPricingData(newArray)
        }
        else{
            setPricingData([])
        }

      },[ratesData])

    const handleSubmit = async(values) => {
        setSubmitLoading(true)
        const postData = {
            seller_id:sellerId,
            seller_email: values?.email
        }
        try{
                const response = await axios.put(`${process.env.REACT_APP_FASTRR_FINANCE}/api/vi1/commission/approve-prices`,
                postData,
                )
                if(response?.data?.result){
                    notification.success({
                        message : response?.data?.result
                    })
                    setSubmitLoading(false)
                    setIsPricingModalVisible(false)
                    setTimeout(() => {
                        queryClient.invalidateQueries('rateDetails');
                    }, 1000);
                }
                if(response?.data?.error?.message){
                    notification.error({
                        message : response?.data?.error?.message
                    })
                    setSubmitLoading(false)
                }
        }catch (err) {
            notification.error({ message: err.message });
            setSubmitLoading(false)
        }
    }
   
      useEffect(()=>{
        if(isError){
            setIsPricingModalVisible(false)
        }
      },[isError])    
      return(
        <Modal
        title={<ModalTitle>Review pricing</ModalTitle> }
        style={{minHeight : '300px'}}
        width={900}
        visible = {isPricingModalVisible}
        footer={null}
        maskClosable = {false}
        closable = {false}
        >
            <ModalContentContainer>
            {isLoading || ratesData===undefined ?
                <div style={{display : 'flex', width : '100%',justifyContent : 'center'}}>
                    <Spin tip = "Loading" size="large"/>
                </div>
                :
                <Form
                onFinish={handleSubmit}
                >  
                <span style={{display : 'flex', fontSize : '16px', marginBottom : '20px'}}>
                    Mentioned below is the commissioned rate that will be charged on every order placed from fastrr checkout: 
                </span>
                {!isLoading ?
                <>
                {/* <Row style={{display : 'flex'}}> */}
                { pricingData?.length > 0 ?
                    pricingData.map((pricingDetail)=>{
                        return(
                        <Col span={24} style={{display : 'flex',border : '1px solid #EDEBEB', borderRadius : '6px', padding : '10px',flexDirection : 'column', marginBottom : '20px'}}>
                            <DateContainer>
                            {pricingDetail?.dateParam}
                            </DateContainer>
                            {pricingDetail?.data?.map((pricingRowData,index)=>{
                                return(
                                    <>
                                    {pricingRowData?.payment_method === "CASH_ON_DELIVERY" ? 
                                    <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                        <Col span={5}>
                                            <GatewayContainer>
                                            COD
                                            </GatewayContainer>
                                        </Col>
                                        <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                            <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                            <Col span={8} >
                                            </Col>
                                            <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                            <img 
                                                src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                alt="Payment Icon"
                                                style={{height : '22px', marginRight : '4px'}}
                                                />
                                                {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                            </Col>
                                            <Col span={8} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                {pricingRowData?.max_cap ?
                                                    <Col span={24}>
                                                    <CaretUpFilled style={{color : '#0C3784'}}/>
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Ceiling: 
                                                    </span>
                                                    {`₹${pricingRowData?.max_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                                {pricingRowData?.min_cap ?
                                                    <Col span={24}>
                                                    <CaretDownFilled style={{color : '#0C3784'}} />
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Floor: 
                                                    </span>
                                                    {`₹${pricingRowData?.min_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    pricingRowData?.payment_method === "PARTIAL_PAID" ? 
                                    <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                        <Col span={5}>
                                            <GatewayContainer>
                                            PARTIAL PAID
                                            </GatewayContainer>
                                        </Col>
                                        <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                            <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                            <Col span={8} >
                                            </Col>
                                            <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                            <img 
                                                src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                alt="Payment Icon"
                                                style={{height : '22px', marginRight : '4px'}}
                                                />
                                                {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                            </Col>
                                            <Col span={8} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                {pricingRowData?.max_cap ?
                                                    <Col span={24}>
                                                    <CaretUpFilled style={{color : '#0C3784'}}/>
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Ceiling: 
                                                    </span>
                                                    {`₹${pricingRowData?.max_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                                {pricingRowData?.min_cap ?
                                                    <Col span={24}>
                                                    <CaretDownFilled style={{color : '#0C3784'}} />
                                                    <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                    Floor: 
                                                    </span>
                                                    {`₹${pricingRowData?.min_cap} per order  `}
                                                    </Col>
                                                    : null
                                                }
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    pricingRowData?.payment_gateways?.map((payment_gateways,index)=>{
                                        return(
                                            <>
                                            <Row style={{display: 'flex',justifyContent : 'space-between'}}>
                                                <Col span={5}>
                                                    <GatewayContainer>
                                                    {payment_gateways.toUpperCase()}
                                                    </GatewayContainer>
                                                </Col>
                                                <Col span={19} style={{display : 'flex',flexDirection : 'column'}}>
                                                    <Row style={{display : 'flex', width : '100%',alignItems : 'flex-start'}}>
                                                    <Col span={8} style={{display : 'flex',fontWeight : 500, fontSize : '16px',lineHeight : '20px',color : '#0C3784',alignItems : 'flex-start',flexDirection : 'column'}}>
                                                        {pricingRowData?.payment_modes.map((payment_mode)=>{
                                                            return(
                                                                <div style={{display : 'flex',flexDirection : 'row',marginBottom : '10px'}}>
                                                                <img 
                                                                src="https://d10srchmli830n.cloudfront.net/1687771744122_9425893f-22eb-414f-bd63-7b86989d68e2_Group.svg"
                                                                alt="Payment Icon"
                                                                style={{height : '18px', marginRight : '4px'}}
                                                                />
                                                                {payment_mode.toUpperCase()}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </Col>
                                                    <Col span={8} style={{display : 'flex',fontSize : '14px',lineHeight : '20px',color : '#0C3784',alignItems : 'center'}}>
                                                    <img 
                                                        src="https://d10srchmli830n.cloudfront.net/1687770468336_9607b784-827e-41e4-bdee-8d77b63597f2_commission-(3)-1.svg"
                                                        alt="Payment Icon"
                                                        style={{height : '22px', marginRight : '4px'}}
                                                        />
                                                        {pricingRowData?.commission_plan === "FLAT" ? `₹${pricingRowData?.flat_charge} per orders` : `${pricingRowData?.percentage_charge}% of the total order`}
                                                    </Col>
                                                    <Col span={8} style={{display : 'flex',flexDirection : 'column',color : '#0C3784'}}>
                                                        {pricingRowData?.max_cap ?
                                                            <Col span={24}>
                                                            <CaretUpFilled style={{color : '#0C3784'}}/>
                                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                            Ceiling: 
                                                            </span>
                                                            {`₹${pricingRowData?.max_cap} per order  `}
                                                            </Col>
                                                            : null
                                                        }
                                                        {pricingRowData?.min_cap ?
                                                            <Col span={24}>
                                                            <CaretDownFilled style={{color : '#0C3784'}} />
                                                            <span style={{fontWeight : 600, marginRight : '4px'}}>
                                                            Floor: 
                                                            </span>
                                                            {`₹${pricingRowData?.min_cap} per order  `}
                                                            </Col>
                                                            : null
                                                        }
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {(index !== pricingRowData?.payment_gateways?.length - 1 ) &&
                                            <Divider />
                                            }
                                            </>
                                        )
                                    })
                                    }
                                    {(index !== pricingDetail?.data?.length - 1 ) &&
                                    <Divider />
                                    }
                                    </>
                                )
                            })
                            }
                        </Col>
                        )
                    })
                    :
                    <EmptyViewContiner>
                    <img 
                        src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
                        alt="empty-data"
                    />
                    <Heading >
                        No Data Available.
                    </Heading>
                    <SubHeading>
                        Looks likes there is no data available , Please try again later. 
                    </SubHeading>
                </EmptyViewContiner>
                }  
                {/* </Row> */}
                </> :
                <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '20px'}}>
                <Spin />
                </div>
                }
                
                <span style={{display : 'flex', margin : '20px 0px', fontSize : '16px'}}>
                To accept the pricing, please enter your email address and click on the 'Accept' button. An email will be sent to this email address with the accepted pricing within the next 24 hours.  
                </span>
                <div style={{display : 'flex',justifyContent : 'flex-end'}}>
                    <Form.Item
                    name='email'
                    label = "Email"
                    rules={[
                        {
                            required : true,
                            type : 'email',
                            message : "Please enter valid email"
                        }
                    ]}
                    style={{marginRight : '20px'}}
                    >
                        <Input placeholder="example@123.com" />
                    </Form.Item>
                    <PickrrButton htmlType="submit" color="checkout-blue" loading={submitLoading}>
                    Accept
                </PickrrButton>

                </div>
                <span 
                style={{color:'black',fontWeight:600,textDecoration:'underline',cursor:'pointer'}}
                onClick={()=>window.open('https://reverse-qc.s3.amazonaws.com/be56-0242ac120002-fastrr-pricing-tnc.pdf')}>
                *Fastrr Terms and Conditions
                </span>  
                </Form>
            }
            </ModalContentContainer>
        </Modal>
    )
}

export default PricingModal