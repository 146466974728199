import React from 'react'
import getFieldControllers from './getFieldControllers';
import { InfoIcon } from 'images/icons';
import { Tooltip as ReactTooltip } from 'react-tooltip'


const Form = ({ controls, formProps }: any) => {

    const { control, formState: { errors } } = formProps;

    return (
        <div>
            {controls.map((controlItem: any) => {
                const Element = getFieldControllers(controlItem.type);

                if (!Element) return null;

                return (
                    <div key={controlItem.name} className={`mt-2 flex flex-col items-center sm:flex-row ${controlItem.className || ""}`}>
                        <span className='basis-1/4 flex items-center gap-1'>{controlItem.label}
                            {controlItem.tooltip && <>
                                <span data-tip data-tooltip-id={controlItem.name} className='cursor-pointer'>
                                    <InfoIcon />
                                </span>
                                <ReactTooltip id={controlItem.name} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} style={{ background: "white", borderRadius: '4px', boxShadow: "0px 4px 25px 0px #00000033", zIndex: 50 }}>
                                    <div className='max-w-40 h-fit-content text-black font-normal '>
                                        {controlItem.tooltip}
                                    </div>
                                </ReactTooltip> </>}

                        </span>
                        <div className='flex flex-col'>
                            <Element
                                {...controlItem}
                                control={control}
                                rules={controlItem.rules}
                            />
                            <div className='text-redColor'>
                                {errors[controlItem.name]?.message}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Form