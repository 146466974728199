import React from 'react';
import {Row, Col, Input} from 'antd';
import {InputContainer} from '../../styled';

const DestinationCountryCode  = ({destinationCountryCodeList, setDestinationCountryCodeList}) => {
    const onChange = (e) =>{
        setDestinationCountryCodeList(() => e.target.value);
        console.log(e.target.value)
    }
    
    return (
        <Row gutter={[16, 16]}>
            <Col>
            <InputContainer>
                <Input 
                    placeholder='Enter Destination Country Code'
                    value={destinationCountryCodeList}
                    onChange={onChange}
                />
            </InputContainer>
            </Col>
        </Row>
    )
}

export default DestinationCountryCode;